import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { bindTrackingClick } from './utilities/js/tracking';
import { EventScroller } from './utilities/js/event-scroller';
import { MediaMatchHandler } from './utilities/js/media-match-handler';
import { ResizeHandler } from './utilities/js/resize-handler';

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    }
};

window.loader = '<b class="loading"><b class="loader icon-refresh"><i>loading...</i></b></b>';
window.eventScroller = new EventScroller();
window.mediaMatchHandler = new MediaMatchHandler();
window.resizeHandler = new ResizeHandler();

const browserCheck = () => {
    window.browser = {
        csshover: !window.matchMedia('(pointer:coarse)').matches,
        ios: (/iPhone|iPad|iPod/i).test(navigator.userAgent),
        iphone: (/iPhone|iPod/i).test(navigator.userAgent),
        android: (/(android)/i).test(navigator.userAgent),
        firefox: (/firefox/i).test(navigator.userAgent),
        safari: (/Version\/[\d.]+.*Safari/).test(navigator.userAgent)
    };

    Object.entries(window.browser).forEach(([key, value]) => {
        document.documentElement.classList.add((!value) ? `no-${key}` : `${key}`);
    });
    document.documentElement.classList.remove('no-js');
    document.documentElement.classList.add('js');
};

const checkInView = (entries, observer) => {
    entries.forEach((entry) => {
        if (!entry.target.classList.contains('inview')) {
            entry.target.classList.add('inview');
            if (typeof entry.target.dataset.footer === 'undefined') {
                window.inViewObserver.unobserve(entry.target);
            }
        }
    });
};

window.inViewObserver = new IntersectionObserver(checkInView, {
    threshold: 0.1
});

const initContent = ($content) => {
    console.log('start app');
    bindTrackingClick($content);
    loadLazyImages();

    // navigation
    const navigation = $content.querySelector('[data-nav="root"]');
    if (navigation) {
        import('./layout/navigation/navigation').then(({ Navigation }) => {
            if ($content.querySelector('[data-nav="root"]')) {
                const navi = new Navigation(navigation);
                navi.initialize();
            }
        });
    }
};

domready(function () {
    const initApplication = () => {
        browserCheck();

        window.eventScroller.init();
        window.resizeHandler.init();

        window.dataInview = document.querySelectorAll('[data-inview]:not(.inview)');
        setTimeout(() => {
            window.dataInview.forEach((dataInView) => {
                window.inViewObserver.observe(dataInView);
            });
        }, 500);

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);

        document.body.classList.add('page--loaded');
        window.addEventListener('beforeunload', () => {
            const isMailTo = document.activeElement.protocol === 'mailto:';
            const isTel = document.activeElement.protocol === 'tel:';

            if (!(isMailTo && isTel)) {
                document.body.classList.remove('page--loaded');
            }
        });

        window.addEventListener('pageshow', (event) => {
            const historyTraversal = event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
            if (historyTraversal) {
                window.location.reload();
            }
        });

        initContent(document);
    };

    initApplication();
});

window.onload = () => {
    const $productDetail = document.querySelector('[data-pagetype="product-detail"]');

    if ($productDetail) {
        window.setTimeout(() => {
            window.scrollTo(0, 0);
        }, 250);
    }

    document.body.classList.add('page--loaded');
};
